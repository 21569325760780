/* eslint-disable no-console */
import type {
  AddToCurrentCartRequest,
  RemoveLineItemsFromCurrentCartRequest,
  LineItem,
  UpdateCartRequest,
  Cart,
} from '@wix/ambassador-ecom-v1-cart/types';
import type { CatalogReferenceOptions } from '@wix/ambassador-restaurants-spi-v1-catalog-reference-options/types';
import type { IHttpClient, IWixAPI } from '@wix/yoshi-flow-editor';
import { APP_DEF_IDS } from '@wix/restaurants-consts';

import { CartClient } from '../api/cartClient';
import { ECOM_APP_ID } from '../api/consts';
import { DispatchType } from '../types/businessTypes';
import type { Address, Timestamp } from '../types/businessTypes';
import type { FedopsLogger } from '../utils/monitoring/FedopsLogger';
import {
  MIXED_CART_TYPES,
  buildAddressForCart,
  findFullCartItem,
  getShippingOptionCode,
  isCartContainMixedItems,
} from '../utils/cartUtils';
import type { IBIReporterService } from './biReporterService';
import type { ErrorMonitor } from '@wix/fe-essentials-viewer-platform/error-monitor';
import { SPECS } from 'root/appConsts/experiments';

export interface TimeRangeForCart {
  start: Timestamp;
  end: Timestamp;
}

export interface CartShippingDetails {
  address: Address | undefined;
  dispatchType: DispatchType;
  timeRange?: TimeRangeForCart;
}
export interface ICartService {
  addItemToCart: (
    item: CartLineItem,
    lineItemId?: string,
    forceReloadCart?: boolean
  ) => Promise<{ cartItem?: LineItem; error?: number }>;
  getCartItems: () => Promise<LineItem[] | undefined>;
  getCurrentCart: () => Promise<Cart | undefined>;
  removeItemFromCart?: (lineItemId: string) => Promise<boolean>;
  reloadCart?: () => void;
  onChange: (callback: Function) => void;
  setShippingDetails: (shippingDetails: CartShippingDetails) => Promise<boolean | undefined>;
  navigateToCartPage: () => Promise<void>;
  openMiniCart: () => Promise<void>;
  closeMiniCart: () => Promise<void>;
  hasSideCart: () => Promise<boolean>;
}

export interface CartLineItem {
  id?: string | null;
  catalogItemId?: string;
  options?: CatalogOptions | null;
  quantity?: number;
}

export interface CatalogReferenceModifier {
  id: string;
  price?: string;
  formattedPrice: string;
}
export interface CatalogReferenceModifierGroup {
  id: string;
  modifiers: CatalogReferenceModifier[];
}

export interface CatalogReferencePriceVariant {
  id: string;
  formattedPrice?: string;
}

export interface CatalogOptions {
  modifierGroups?: CatalogReferenceModifierGroup[];
  priceVariant?: CatalogReferencePriceVariant;
  operationId?: string;
  specialRequests?: string;
}

export const enum ADD_TO_CART_ERRORS {
  MIXED_CART,
  MIXED_CART_OPERATION,
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let eComApi: any;
async function getEcomPublicAPI(wixAPI: IWixAPI) {
  try {
    if (!eComApi) {
      eComApi = await wixAPI.site.getPublicAPI(ECOM_APP_ID);
    }
  } catch (e) {
    console.log('getPublicAPI error: ', e);
  }
  return eComApi;
}

async function navigateToCartPage(wixAPI: IWixAPI) {
  const eComPublicAPI = await getEcomPublicAPI(wixAPI);
  eComPublicAPI?.navigate.toCart();
}

async function openMiniCart(wixAPI: IWixAPI) {
  const eComPublicAPI = await getEcomPublicAPI(wixAPI);
  eComPublicAPI?.cart?.openSideCart();
}

async function hasSideCart(wixAPI: IWixAPI): Promise<boolean> {
  const eComPublicAPI = await getEcomPublicAPI(wixAPI);
  return eComPublicAPI?.cart?.hasSideCart();
}

async function closeMiniCart(wixAPI: IWixAPI) {
  const eComPublicAPI = await getEcomPublicAPI(wixAPI);
  eComPublicAPI?.cart?.closeMiniCart();
}

async function reloadEComCart(wixAPI: IWixAPI) {
  const eComPublicAPI = await getEcomPublicAPI(wixAPI);
  eComPublicAPI?.cart?.reloadCart();
}

async function onCartChange(wixAPI: IWixAPI, callback: Function) {
  const eComPublicAPI = await getEcomPublicAPI(wixAPI);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  eComPublicAPI?.cart?.onChange((_args: any) => {
    callback();
  });
}

export const buildAddToCartRequest = (item: CartLineItem) => {
  const { catalogItemId, options: itemOptions, quantity } = item;
  return {
    lineItems: [
      {
        catalogReference: {
          appId: APP_DEF_IDS.orders,
          catalogItemId,
          options: itemOptions as CatalogReferenceOptions,
        },
        quantity,
      },
    ],
  } as AddToCurrentCartRequest;
};

export const CartService = ({
  httpClient,
  fedopsLogger,
  sentry,
  experiments,
  metaSiteId = '',
  wixAPI,
  biReporterService,
}: {
  httpClient: IHttpClient;
  fedopsLogger: FedopsLogger;
  sentry: ErrorMonitor;
  experiments: { enabled(key: string): boolean };
  metaSiteId?: string;
  wixAPI?: IWixAPI;
  biReporterService?: IBIReporterService;
}): ICartService => {
  let pendingShippingDetails:
    | {
        address: Address | undefined;
        dispatchType: DispatchType;
        timeRange?: { start: number; end: number };
      }
    | undefined;

  const cartClient = CartClient(httpClient, fedopsLogger, sentry);

  const cartServiceObject = {
    navigateToCartPage: navigateToCartPage.bind(null, wixAPI!),
    openMiniCart: openMiniCart.bind(null, wixAPI!),
    closeMiniCart: closeMiniCart.bind(null, wixAPI!),
    hasSideCart: hasSideCart.bind(null, wixAPI!),
    addItemToCart: async (
      item: CartLineItem,
      lineItemId?: string,
      forceReloadCart = true
    ): Promise<{ cartItem?: LineItem; error?: ADD_TO_CART_ERRORS }> => {
      fedopsLogger.addItemsToCartOverallActionStarted();

      sentry?.addBreadcrumb({
        type: 'cart',
        category: 'addItemToCart',
        message: 'calling getCartItems',
      });
      const currentCartItems = await cartServiceObject.getCartItems();
      let cartNumOfItems =
        currentCartItems?.reduce(
          (prevValue, currValue) => prevValue + (currValue.quantity || 0),
          0
        ) || 0;

      sentry?.addBreadcrumb({
        type: 'cart',
        category: 'addItemToCart',
        message: `got cartItems: ${cartNumOfItems}`,
      });

      const mixedCartType =
        !!currentCartItems &&
        item.options?.operationId &&
        isCartContainMixedItems(currentCartItems, item.options.operationId);

      if (mixedCartType) {
        fedopsLogger.addItemsToCartOverallActionEnded();
        sentry?.addBreadcrumb({
          type: 'cart',
          category: 'addItemToCart',
          message: `aborting: this is an ${mixedCartType} mixed cart`,
        });
        return {
          error:
            mixedCartType === MIXED_CART_TYPES.INTERNAL
              ? ADD_TO_CART_ERRORS.MIXED_CART_OPERATION
              : ADD_TO_CART_ERRORS.MIXED_CART,
        };
      }

      if (lineItemId) {
        const isRemoved = await cartServiceObject.removeItemFromCart(lineItemId);
        cartNumOfItems--;
        if (!isRemoved) {
          sentry?.captureException(new Error(`Failed to remove item ${lineItemId} from cart`));
        }
      }
      const addToCartRequest = buildAddToCartRequest(item);
      sentry?.addBreadcrumb({
        type: 'cart',
        category: 'addItemToCart',
        message: `calling addItemToCart`,
        data: addToCartRequest,
      });
      const response = await cartClient.addItemToCart(addToCartRequest);

      const { lineItems } = response.cart ?? {};

      const updatedCartNumOfItems =
        lineItems?.reduce((prevValue, currValue) => prevValue + (currValue.quantity || 0), 0) || 0;

      sentry?.addBreadcrumb({
        type: 'cart',
        category: 'addItemToCart',
        message: `updated cartNumOfItems: ${updatedCartNumOfItems}, ecomId: ${response.cart?.ecomId}`,
      });
      const lineItem =
        lineItems && updatedCartNumOfItems > cartNumOfItems && findFullCartItem(lineItems, item);

      if (!lineItem) {
        sentry?.captureException(
          new Error(`Failed to add item with catalog id: ${item.catalogItemId} to cart`)
        );
        return {};
      }

      if (pendingShippingDetails) {
        sentry?.addBreadcrumb({
          type: 'cart',
          category: 'addItemToCart',
          message: `setShippingDetails, ${JSON.stringify(pendingShippingDetails)}`,
        });
        await cartServiceObject.setShippingDetails(pendingShippingDetails);
        pendingShippingDetails = undefined;
      }

      lineItems && forceReloadCart && cartServiceObject.reloadCart();
      fedopsLogger.addItemsToCartOverallActionEnded();
      return { cartItem: lineItem };
    },
    getCartItems: async () => {
      const cart = await cartServiceObject.getCurrentCart();
      return cart?.lineItems;
    },
    getCurrentCart: async () => {
      const response = await cartClient.getCurrentCart({});
      return response.cart;
    },
    removeItemFromCart: async (lineItemId: string) => {
      const request: RemoveLineItemsFromCurrentCartRequest = {
        lineItemIds: [lineItemId],
      };
      const response = await cartClient.removeItemFromCart(request);
      if (!response || !response.cart) {
        return false;
      }
      return !response.cart?.lineItems?.find((i) => i.id === lineItemId);
    },
    setShippingDetails: async ({
      address,
      dispatchType,
      timeRange,
    }: {
      address: Address | undefined;
      dispatchType: DispatchType;
      timeRange?: { start: number; end: number };
    }) => {
      const currentCart = await cartServiceObject.getCurrentCart();
      const cartId = currentCart?.id;
      if (!cartId) {
        pendingShippingDetails = { address, dispatchType, timeRange };
        return;
      }

      const shippingOptionCode = getShippingOptionCode(dispatchType, timeRange);

      const selectedShippingOption = {
        code: shippingOptionCode,
      };

      biReporterService?.reportOloGenericDebugBiEvent({
        subjectType: 'selectedShippingOption',
        value: {
          msid: metaSiteId,
          selectedShippingOption,
        },
      });

      if (address || selectedShippingOption) {
        const cartFieldmask = ['selectedShippingOption'];
        address && cartFieldmask.push('contactInfo.address');

        const shouldUpdateAddress =
          !experiments.enabled(SPECS.unchangedDeliveryAddress) ||
          dispatchType === DispatchType.PICKUP;

        const cartInfo = (
          address
            ? {
                contactInfo: {
                  address: shouldUpdateAddress
                    ? buildAddressForCart(dispatchType, address)
                    : address,
                },
                selectedShippingOption,
              }
            : { selectedShippingOption }
        ) as Cart;

        const updateCartRequest: UpdateCartRequest = {
          cartFieldmask,
          cartInfo,
        };

        const updateCartResponse = await cartClient.updateCartInfo(updateCartRequest);
        cartServiceObject.reloadCart();

        return address ? !!updateCartResponse?.cart?.contactInfo?.address : true;
      }
    },
    reloadCart: () => {
      wixAPI && reloadEComCart(wixAPI);
    },
    onChange: (callback: Function) => {
      wixAPI && onCartChange(wixAPI, callback);
    },
  };
  return cartServiceObject;
};

export type { CatalogReferenceOptions };
