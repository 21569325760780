import model from './model';
import { DispatchModalController } from './dispatchModalController';
import type { DispatchModalProps } from '../../types/widgetsProps';
import type { IWixWindow } from '@wix/yoshi-flow-editor';
import { registerToUrlChange } from 'root/utils/navigationUtils';
import type { IDispatchModalController } from './types';
import { dispatchState } from 'root/states/DispatchState';
import { DispatchModalStore } from 'root/states/DispatchModalStore';
import { FulfillmentsClient } from 'root/api/fulfillmentsClient';
import { OperationsClient } from 'root/api/operationClient';
import { initDispatchState } from 'root/states/initDispatchState';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { context } from '../../context/RootContext';
import { SPECS } from 'root/appConsts/experiments';
import { type OSLocation } from 'root/types/businessTypes';
import { runInAction } from 'mobx';

export default model.createController(({ $bindAll, $widget, flowAPI, $w }) => {
  const { controllerConfig, experiments } = flowAPI;
  const { window } = controllerConfig.wixCodeApi;
  const lightboxContext = window.lightbox.getContext() || {};
  const {
    data: props,
    onModalOpen,
    closeModal,
  }: {
    data?: DispatchModalProps;
    onModalOpen: () => void;
    closeModal: (window: IWixWindow, data?: unknown) => void;
  } = lightboxContext;
  const {
    dispatchState: _dispatchState,
    operations,
    currentOperationId,
    selectedDispatchType,
    biReporterService,
    onSave,
    fedopsLogger,
  } = props ?? {};
  let dispatchModalController: IDispatchModalController;

  const currentOperation = operations?.find((operation) => operation.id === currentOperationId);
  currentOperationId && dispatchState.setCurrentOperationId(currentOperationId);

  const locations = operations?.map((operation) => ({
    _id: operation.locationId,
    ...operation.locationDetails,
  })) as (OSLocation & { _id: string; acceptOrders: boolean })[] | undefined;

  const { isEditor, isPreview, isViewer } = flowAPI.environment;
  const { formatAddress } = flowAPI;

  const initDispatchStateInEditor = async () => {
    const { reportError, httpClient, errorMonitor } = flowAPI;
    const pageOperationIdPromise = getPageOperationId(
      controllerConfig.wixCodeApi.site,
      errorMonitor
    );
    const fetchOperation = () =>
      new OperationsClient(httpClient).getOperation(pageOperationIdPromise);
    const operationData = await fetchOperation();
    runInAction(() => {
      dispatchState.operations = operationData ? [operationData] : [];
    });
    const fulfillmentsClient = new FulfillmentsClient(httpClient, [operationData!.id ?? '']);
    const timezone = controllerConfig.wixCodeApi.site.timezone || DEFAULT_TIMEZONE;

    const state = await initDispatchState({
      fulfillmentsClient,
      operations: [operationData!],
      timezone,
      cart: await context.CartService?.getCurrentCart(),
      fedopsLogger,
      reportError,
      supportMultiLocation: experiments.enabled(SPECS.enableMultiLocation),
    });

    state &&
      dispatchState.init({
        dispatchStateByLocation: state,
        operationId: currentOperation!.id,
        operations: [currentOperation!],
      });

    dispatchState.setIsLoading(false);
    const store = new DispatchModalStore(operationData!, fulfillmentsClient);
    store.setIsLoading(true);
    store.initTimeAndDates().then(() => store.setIsLoading(false));
    dispatchModalController = new DispatchModalController(
      $bindAll,
      $w,
      flowAPI,
      window,
      isEditor,
      store,
      operationData!,
      locations,
      () => {},
      biReporterService,
      !isEditor && !isPreview && isViewer
    );
  };

  if (currentOperation && _dispatchState && onSave) {
    dispatchState.init({
      dispatchStateByLocation: _dispatchState,
      operationId: currentOperation.id,
      operations: [currentOperation],
      isLoading: false,
      dispatchType: selectedDispatchType,
    });

    const fulfillmentClient = new FulfillmentsClient(flowAPI.httpClient, [currentOperation.id]);
    const store = new DispatchModalStore(
      currentOperation,
      fulfillmentClient,
      formatAddress,
      flowAPI,
      fedopsLogger
    );
    store.setIsLoading(true);
    store.initTimeAndDates().then(() => store.setIsLoading(false));
    dispatchModalController = new DispatchModalController(
      $bindAll,
      $w,
      flowAPI,
      window,
      isEditor,
      store,
      currentOperation,
      locations,
      onSave,
      biReporterService,
      !isEditor && !isPreview && isViewer
    );
  }

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      const initialDispatchState = _dispatchState;
      const { wixCodeApi } = controllerConfig;
      registerToUrlChange(wixCodeApi, closeModal, window);
      if ((isEditor || isPreview) && !currentOperation && !_dispatchState) {
        await initDispatchStateInEditor();
      }
      dispatchModalController.init(onModalOpen, closeModal, initialDispatchState);
    },
    exports: {},
  };
});
