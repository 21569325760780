import model from './model';
import { PopulatedMenuClient } from '../../api/PopulatedMenuClient';
import { OLOController } from './oloController';
import type { CartLineItem } from '../../services/cartService';
import { CartService } from '../../services/cartService';
import { context } from '../../context/RootContext';
import type { Cart } from '@wix/ambassador-ecom-v1-cart/types';
import type { PopulatedMenu } from '../../types/menusTypes';
import { WarmupDataManager } from '../../utils/WarmupDataManager';
import type { FedopsLogger as FedopsLoggerType } from '@wix/fe-essentials-editor';
import { FedopsLogger } from '../../utils/monitoring/FedopsLogger';
import { ModalService } from '../../services/modalService';
import { OperationsClient } from '../../api/operationClient';
import type { Experiments, TFunction } from '@wix/yoshi-flow-editor';
import type { ItemData, Item } from '../../types/item';
import { SPECS } from '../../appConsts/experiments';
import { getCartItemById } from '../../utils/cartUtils';
import type { IBIReporterService } from '../../services/biReporterService';
import { BIReporterService } from '../../services/biReporterService';
import type { Operation } from '../../types/businessTypes';
import { PersistDataService } from 'root/services/persistDataService';
import { getSiteCurrency, getSiteLocale } from '../../utils/siteDataUtils';
import { PriceFormattingConverter } from '@wix/restaurants-olo-operations-client-commons';
import { initDispatchState } from 'root/states/initDispatchState';
import { FulfillmentsClient } from '../../api/fulfillmentsClient';
import { dispatchState } from 'root/states/DispatchState';
import { getMonitoredApiCall } from 'root/api/utils/getMonitoredApiCall';
import { DEFAULT_TIMEZONE } from 'root/api/consts';
import type { SortableMenu } from './panels/MenuSettings/types';
import { PopulateMenuIdsByOperationClient } from 'root/api/PopulateMenuIdsByOperationClient';
import { OrdersSettingsService } from 'root/services/ordersSettingsService';
import { getAvailabilityStatusProps } from 'root/utils/menusUtils';
import { menusState } from 'root/states/MenusState';
import { openDispatchModal } from 'root/utils/utils';
import { getTimezoneOffset } from 'root/api/utils/utils';
import { availabilityStatusKeys } from 'root/availabilityStatusKeys';
import { generateBreadcrumbsSD } from 'root/utils/seoUtils';
import { getSiteStructure } from 'root/utils/siteStructure';
import { cartState } from 'root/states/cartState';
import { itemState } from 'root/states/itemsState';
import { getPageOperationId } from 'root/utils/pageOperationUtils';
import { runInAction } from 'mobx';

type ImageSD = {
  url: string;
  id: string;
  height: number;
  width: number;
  altText: string;
};

const reportConductedExperiments = (bi: IBIReporterService, experiments: Experiments) => {
  const value = experiments.all();
  bi.reportOloGenericDebugBiEvent({
    subjectType: 'conductedExperiments',
    value,
  });
};

export default model.createController(({ $w, $widget, flowAPI }) => {
  const {
    translations,
    httpClient,
    experiments,
    bi,
    fedops,
    environment,
    controllerConfig,
    errorMonitor,
    reportError,
  } = flowAPI;
  const { wixCodeApi, platformAPIs, compId } = controllerConfig;
  const t = translations.t as TFunction;
  const { metaSiteId = '' } = platformAPIs.bi || {};
  const timezone = wixCodeApi.site.timezone || DEFAULT_TIMEZONE;
  const locale = getSiteLocale(flowAPI);

  const optimizeMenuFetching = experiments.enabled(SPECS.optimizeMenuFetching);

  $widget.onPropsChanged(async (prevProps, nextProps) => {
    if (prevProps.sortedMenus !== nextProps.sortedMenus) {
      const menusOrder = (
        (nextProps.sortedMenus || menusState.sortedMenusDto) as SortableMenu[]
      ).map((sortedMenu) => sortedMenu.id);
      menusState.orderMenus(menusOrder);
    }
  });

  const warmupData = new WarmupDataManager(wixCodeApi.window.warmupData, environment.isSSR);

  context.biReporterService = BIReporterService({
    biLogger: bi,
    environment,
    widgetInstanceId: compId,
  });
  context.fedopsLogger = new FedopsLogger(
    fedops as FedopsLoggerType,
    metaSiteId,
    context.biReporterService
  );
  const { fedopsLogger, biReporterService } = context;
  context.CartService = CartService({
    httpClient,
    fedopsLogger,
    sentry: errorMonitor,
    experiments,
    metaSiteId: platformAPIs.bi?.metaSiteId,
    wixAPI: wixCodeApi,
    biReporterService,
  });

  menusState.setOrdersSettingsService(
    OrdersSettingsService({
      httpClient,
      fedopsLogger,
      sentry: errorMonitor,
      reportError,
      experiments,
    })
  );

  context.PersistDataService = PersistDataService(
    platformAPIs.storage.session,
    environment.isEditor
  );

  context.ModalService = context.ModalService
    ? context.ModalService
    : new ModalService(wixCodeApi, fedopsLogger, environment.isMobile, context.CartService);

  const oloController = new OLOController($w, t);

  fedopsLogger.loadOloPageStarted();
  biReporterService?.reportRestaurantsUouPageStartedLoadingBiEvent();

  const currency = getSiteCurrency(flowAPI);
  context.priceFormatter = PriceFormattingConverter.createPriceFormatter(locale, currency);
  context.currency = currency;

  const operationsClient = new OperationsClient(flowAPI.httpClient);

  const pageOperationIdPromise = getPageOperationId(wixCodeApi.site, errorMonitor);

  reportConductedExperiments(biReporterService, experiments);

  const menuIdsByOperation = (operationIdPromise: Promise<string>) =>
    PopulateMenuIdsByOperationClient({
      experiments,
      httpClient,
      fedopsLogger,
      reportError,
      sentry: errorMonitor,
      timezoneOffset: getTimezoneOffset(timezone),
    })
      .getAll(operationIdPromise)
      .then((response) => {
        const menusAvailability = response?.menusAvailability || {};
        menusState.setMenusAvailability(menusAvailability);

        return response;
      });

  const populatedMenuClient = PopulatedMenuClient({
    httpClient,
    experiments,
    msid: metaSiteId,
    currency,
    sentry: errorMonitor,
  });

  const fetchPopulatedMenus = (operationIdPromise: Promise<string>) =>
    optimizeMenuFetching
      ? populatedMenuClient.getPartial(menuIdsByOperation(operationIdPromise))
      : populatedMenuClient.getAll(menuIdsByOperation(operationIdPromise));

  const fetchOperations = async () => {
    if (!pageOperationIdPromise) {
      return [];
    }
    if (experiments.enabled(SPECS.enableOperationGroup)) {
      const operations = await operationsClient.getOperationsByGroupId(pageOperationIdPromise);
      if (operations?.length) {
        return operations;
      }
    }

    const operation = await operationsClient.getOperation(pageOperationIdPromise);
    if (!operation) {
      context.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      return [];
    }
    return [operation];
  };

  const getMonitoredPopulatedMenuClient = (operationIdPromise: Promise<string>) => {
    const fetchMenusByOperation = () => fetchPopulatedMenus(operationIdPromise);
    return getMonitoredApiCall({
      callback: fetchMenusByOperation,
      fedops: { start: fedopsLogger.fetchMenusDataStarted, end: fedopsLogger.fetchMenusDataEnded },
      reportError,
      onError: () => {
        context.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      },
    });
  };

  const getMonitoredOperationClient = () =>
    getMonitoredApiCall({
      callback: fetchOperations,
      fedops: {
        start: fedopsLogger.fetchOperationDataStarted,
        end: fedopsLogger.fetchOperationDataEnded,
      },
      reportError,
      sentry: errorMonitor,
      onError: () => {
        context.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
      },
    });

  let menusPromise = Promise.resolve<{
    data?: PopulatedMenu[];
    error?: Error;
    itemPromise?: Promise<void>;
  }>({});

  const operationsPromise = warmupData
    .manageData<{ data?: Operation[]; error?: Error } | undefined>(
      getMonitoredOperationClient,
      'operations',
      pageOperationIdPromise
    )
    .then((response) => {
      const operations = response?.data;
      return operations;
    });

  const currentOperationIdPromise = getCurrentOperationId();
  if (optimizeMenuFetching) {
    menusPromise = warmupData
      .manageData<{ data?: PopulatedMenu[]; error?: Error } | undefined>(
        () => getMonitoredPopulatedMenuClient(currentOperationIdPromise),
        'populatedMenus',
        pageOperationIdPromise
      )
      .then(async (response) => {
        const { data, error } = response || {};
        let itemPromise = Promise.resolve();
        if (data) {
          const monitoredItemCall = () =>
            getMonitoredApiCall({
              callback: () => populatedMenuClient.getItems(data),
              reportError,
              onError: () => {
                context.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
              },
            });
          itemPromise = warmupData
            .manageData<{ data?: Item[]; error?: Error } | undefined>(
              monitoredItemCall,
              'fetchPopulatedItems',
              pageOperationIdPromise
            )
            .then((res) => {
              const { data: items, error: fetchItemsError } = res ?? {};
              if (fetchItemsError) {
                menusState.setHasError(true);
              } else if (items) {
                const itemMap = items.reduce((acc, item) => {
                  acc.set(item.id, item);
                  return acc;
                }, new Map<string, Item>());
                itemState.setItemMap(itemMap);
                menusState.populate(itemMap);
              }
            });
          const menusOrder = (
            ($widget.props.sortedMenus || data) as SortableMenu[] | undefined
          )?.map((sortedMenu) => sortedMenu.id);
          menusState.setMenus(data, menusOrder);
        }
        return { data, error, itemPromise };
      });
  } else {
    menusPromise = warmupData
      .manageData<{ data?: PopulatedMenu[]; error?: Error; truncated?: boolean } | undefined>(
        () => getMonitoredPopulatedMenuClient(currentOperationIdPromise),
        'populatedMenus',
        pageOperationIdPromise
      )
      .then(async (response) => {
        const { data, error } = response || {};
        if (data) {
          const menusOrder = (
            ($widget.props.sortedMenus || data) as SortableMenu[] | undefined
          )?.map((sortedMenu) => sortedMenu.id);
          itemState.setItemMapFromPopulatedMenus(data);
          menusState.setMenus(data, menusOrder);
        }
        return { data, error };
      });
  }

  const cartPromise = context.CartService?.getCurrentCart().then((cart) => {
    initCartDetails(cart);
    return cart;
  });

  async function getCurrentOperationId(operations?: Operation[]): Promise<string> {
    const pageOperations = operations ?? (await operationsPromise);
    if (!experiments.enabled(SPECS.enableOperationGroup)) {
      return pageOperations?.[0].id ?? '';
    }

    const { locationId } = wixCodeApi.location.query || {};
    let currentOperation: Operation | undefined;
    if (locationId) {
      currentOperation = pageOperations?.find((operation) => operation.locationId === locationId);
    }
    if (!currentOperation) {
      currentOperation =
        pageOperations?.find((operation) => operation.locationDetails?.default) ??
        pageOperations?.[0];
    }

    return currentOperation?.id as string;
  }

  const initDispatchStatePromise = new Promise<void>(async (resolve) => {
    const operationId = await currentOperationIdPromise;
    const operations = (await operationsPromise) ?? [];
    const operationIds = operations?.map((operation) => operation.id) ?? [];
    const locationIds = [...new Set(operations?.map((operation) => operation.locationId))];
    const fulfillmentsClient = new FulfillmentsClient(httpClient, operationIds);
    const persistedState = context.PersistDataService?.getDispatchState(locationIds);
    const fetchDispatchState = async () =>
      operations &&
      operationId &&
      initDispatchState({
        fulfillmentsClient,
        operations,
        timezone,
        cart: await cartPromise,
        persistedState,
        fedopsLogger,
        reportError,
        sentry: errorMonitor,
        operationId,
        supportMultiLocation: experiments.enabled(SPECS.enableMultiLocation),
      });

    const dispatches = await fetchDispatchState();
    dispatches &&
      dispatchState.init({
        dispatchStateByLocation: dispatches,
        operationId,
        operations,
      });
    await menusState.updateAvailabilityStatus(
      operationId,
      dispatchState.dispatchInfo,
      dispatchState.selectedDispatchType
    );
    if (!environment.isSSR) {
      dispatchState.setIsLoading(false);
    }
    resolve();
  });

  const initCartDetails = (cart?: Cart) => {
    if (!cart) {
      return;
    }
    const { lineItems } = cart;
    lineItems && cartState.setCartLineItems({ lineItems });
  };

  const retryOnEmptyMenus = async (counter = 0, operationId: string) => {
    if (counter < 5) {
      let resolveFn: (data?: PopulatedMenu[]) => void;
      const menusRetryPromise = new Promise<PopulatedMenu[] | undefined>(
        (resolve) => (resolveFn = resolve)
      );

      setTimeout(async () => {
        const { data } =
          (await getMonitoredPopulatedMenuClient(Promise.resolve(operationId))) || {};
        resolveFn(data);
      }, 2000);

      const menus = (await menusRetryPromise) || [];
      if (!menus?.length) {
        retryOnEmptyMenus(counter + 1, operationId);
      } else {
        const menusOrder = (
          ($widget.props.sortedMenus || menus) as SortableMenu[] | undefined
        )?.map((sortedMenu) => sortedMenu.id);
        itemState.setItemMapFromPopulatedMenus(menus);
        menusState.setMenus(menus, menusOrder);
      }
    }
  };

  const getItemById = (itemId: string, menus: PopulatedMenu[]) => {
    if (itemId) {
      for (const menu of menus) {
        for (const section of menu.sections) {
          const items = section.items
            ?.filter((item) => item.id === itemId)
            .map((item) => ({ item, sectionId: section.id, menuId: menu.id }));

          if (items?.length) {
            return items;
          }
        }
      }
    }
  };

  const openEditItemModal = async (cartItemId: string, menus: PopulatedMenu[]) => {
    const cartItem = getCartItemById(cartState.cartLineItems, cartItemId);
    openItemModal({ menus, cartItem });
  };

  const handleOpenDispatchModal = async ({
    menus,
    menuId,
    sectionId,
    itemId,
  }: {
    menus: PopulatedMenu[];
    menuId?: string;
    sectionId?: string;
    itemId?: string;
    cartItem?: CartLineItem;
  }) => {
    await openDispatchModal({
      onSave: async ({ dispatchType, dispatchInfo }) => {
        dispatchState.update(dispatchType, dispatchInfo);
        context.CartService?.setShippingDetails(dispatchState.getShippingDetails());
        await menusState.updateAvailabilityStatus(
          dispatchState.currentOperationId,
          dispatchInfo,
          dispatchType
        );
        openItemModal({ menus, menuId, sectionId, itemId });
      },
      context,
      dispatchState: dispatchState.state,
    });
  };

  const openItemModalByQueryParam = async (menus: PopulatedMenu[]) => {
    const shouldOpenDishModal =
      !!dispatchState.dispatchInfo.address || !dispatchState.hasAvailableDispatches;
    const { itemId, sectionId, menuId } = wixCodeApi.location.query ?? {};
    if (itemId && sectionId && menuId) {
      if (shouldOpenDishModal) {
        openItemModal({ itemId, sectionId, menuId, menus });
      } else {
        openDispatchModal({
          onSave: async ({ dispatchType, dispatchInfo }) => {
            dispatchState.update(dispatchType, dispatchInfo);
            context.CartService?.setShippingDetails(dispatchState.getShippingDetails());
            await menusState.updateAvailabilityStatus(
              dispatchState.currentOperationId,
              dispatchInfo,
              dispatchType
            );
            openItemModal({ menus, menuId, sectionId, itemId });
          },
          context,
          dispatchState: dispatchState.state,
        });
      }
    }
  };

  const openItemModal = async ({
    menus,
    menuId,
    sectionId,
    itemId,
    cartItem,
  }: {
    menus: PopulatedMenu[];
    menuId?: string;
    sectionId?: string;
    itemId?: string;
    cartItem?: CartLineItem;
  }) => {
    const [menuItem] =
      (cartItem?.catalogItemId
        ? getItemById(cartItem?.catalogItemId, menus)
        : getItemById(itemId ?? '', menus)?.filter(
            (item) => item.menuId === menuId && item.sectionId === sectionId
          )) ?? [];
    const menu = menusState.getMenu(menuItem.menuId);
    const { isMenuOfItemAvailable, hasNextAvailability, text, shouldCollapseAvailabilityStatus } =
      getAvailabilityStatusProps({
        menu,
        locale,
        timezone,
        t,
        keys: availabilityStatusKeys.itemModal,
      });

    context.ModalService?.openDishModal({
      item: menuItem.item as ItemData,
      cartService: context.CartService,
      operationId: dispatchState.currentOperationId,
      locationId: dispatchState.currentOperation?.locationId,
      canAcceptOrders: dispatchState.availableDispatchTypes.length > 0,
      sectionId: menuItem.sectionId,
      menuId: menuItem.menuId,
      cartItem,
      availabilityStatusProps: {
        isMenuOfItemAvailable,
        text,
        shouldCollapseAvailabilityStatus,
        hasNextAvailability,
        dispatchType: dispatchState.selectedDispatchType,
      },
      openDispatchModal: async () => {
        handleOpenDispatchModal({ itemId, sectionId, menuId, menus });
      },
    });
  };

  const retryFetchingMenusIfNeeded = (menus: PopulatedMenu[], operationId: string) => {
    if (!menus.length) {
      // TODO: set menus empty state
      !environment.isViewer && retryOnEmptyMenus(0, operationId);
    }
  };

  const registerOnCartChangeEvent = () => {
    context.CartService?.onChange(async () => {
      const { lineItems: cartLineItems } = (await context.CartService?.getCurrentCart()) ?? {};
      cartLineItems && cartState.setCartLineItems({ lineItems: cartLineItems });
    });
  };

  const openItemModalsIfNeeded = async (menus: PopulatedMenu[]) => {
    await openItemModalByQueryParam(menus);

    const { cartItemId = undefined } = controllerConfig.wixCodeApi.location.query || {};

    if (cartItemId) {
      openEditItemModal(cartItemId, menus);
    }
  };

  const convertMenusToImageSD = (menus: PopulatedMenu[]): ImageSD[] =>
    menus
      .flatMap((menu) =>
        menu.sections.flatMap((section) =>
          section.items?.map((item) => ({
            url: item.image?.url ?? '',
            id: item.id,
            height: item.image?.height ?? 0,
            width: item.image?.width ?? 0,
            altText: item.image?.altText ?? item.name,
          }))
        )
      )
      .filter(Boolean) as ImageSD[];

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      oloController.setMenus();
      try {
        const operationId =
          dispatchState.currentOperationId ?? (await currentOperationIdPromise) ?? '';

        if (!operationId) {
          return;
        }
        dispatchState.currentOperationId = operationId;
        if (optimizeMenuFetching) {
          const { itemPromise } = (await menusPromise) ?? {};

          retryFetchingMenusIfNeeded(menusState.sortedMenusDto, operationId);

          // Report breadcrumbs SD to seo
          const siteStructure = await getSiteStructure(wixCodeApi.site);
          const breadcrumbsSd = generateBreadcrumbsSD(flowAPI, siteStructure);
          await flowAPI.controllerConfig.wixCodeApi.seo.setStructuredData([breadcrumbsSd]);

          const oloLoadedPromise = Promise.all([
            operationsPromise,
            cartPromise,
            itemPromise,
            initDispatchStatePromise,
          ]).then(async () => {
            if (!environment.isSSR) {
              if (experiments.enabled(SPECS.seo)) {
                const itemData = { images: convertMenusToImageSD(menusState.sortedMenusDto) };
                wixCodeApi.seo.renderSEOTags({ itemType: 'IMAGES_COMPONENT', itemData });
              }
              registerOnCartChangeEvent();
              await openItemModalsIfNeeded(menusState.sortedMenusDto);
            }

            const isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser?.loggedIn;
            const { utm_source: refferalInfo = undefined } = wixCodeApi.location.query ?? {};

            biReporterService?.reportOloLiveSiteOloPageLoadedBiEvent({
              isMemberLoggedIn,
              menus: menusState.sortedMenusDto,
              refferalInfo,
            });

            fedopsLogger.loadOloPageEnded();
            biReporterService?.reportRestaurantsUouPageFinishedLoadingBiEvent();
          });
          const operations = await operationsPromise;
          operations && dispatchState.setOperations(operations);
          dispatchState.setCurrentOperationId(operationId);

          if (environment.isEditor) {
            await oloLoadedPromise;
          }
        } else {
          const [menusData, operations, cart] = await Promise.all([
            menusPromise,
            operationsPromise,
            cartPromise,
          ]);

          const { data: menus = [] } = menusData || {};
          retryFetchingMenusIfNeeded(menusState.sortedMenusDto, operationId);

          initCartDetails(cart);

          runInAction(() => {
            dispatchState.operations = operations ?? [];
            dispatchState.currentOperationId = operationId;
          });

          await initDispatchStatePromise;

          // Report breadcrumbs SD to seo
          const siteStructure = await getSiteStructure(wixCodeApi.site);
          const breadcrumbsSd = generateBreadcrumbsSD(flowAPI, siteStructure);
          await flowAPI.controllerConfig.wixCodeApi.seo.setStructuredData([breadcrumbsSd]);

          // Fetch all menus after SSR & hydration
          if (!environment.isSSR) {
            if (experiments.enabled(SPECS.seo)) {
              const itemData = { images: convertMenusToImageSD(menus) };
              wixCodeApi.seo.renderSEOTags({ itemType: 'IMAGES_COMPONENT', itemData });
            }
            registerOnCartChangeEvent();
            await openItemModalsIfNeeded(menus);
          }

          const isMemberLoggedIn = !!controllerConfig.wixCodeApi.user.currentUser?.loggedIn;
          const { utm_source: refferalInfo = undefined } = wixCodeApi.location.query ?? {};

          biReporterService?.reportOloLiveSiteOloPageLoadedBiEvent({
            isMemberLoggedIn,
            menus,
            refferalInfo,
          });

          fedopsLogger.loadOloPageEnded();
          biReporterService?.reportRestaurantsUouPageFinishedLoadingBiEvent();
        }
      } catch (e) {
        // TODO: set menus error state
        context.pubsub.publish('onFetchFailed', { oloState: 'errorState' });
        // eslint-disable-next-line no-console
        console.log('error', e);
      }
    },
    exports: {},
  };
});
